import React, { useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { EditOutlined, PrinterOutlined } from '@ant-design/icons';
import { Div, H5, CRDivider } from 'components';
import { createDescription } from 'services/medicine.service';
import { useConfigurations } from 'hooks';
import ReactToPrint from 'react-to-print';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useHistory } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { CENTERMETER_TO_PIXEL, FULL_DAY_FORMAT } from 'utils/constants';

const HEIGHT = 'content-fit';

const ContainerStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: grey;
  position: relative;
`;

const WrapperStyled = styled(Div)`
  width: 500px;
  /* height: calc(100% - 40px); */
  height: ${HEIGHT};
  border: 1px solid black;
  border-radius: 8px;
  background: #ffffff;
`;

const Prescriptions = ({ prescriptions, onDeletePrescriptions, patient }) => {
  const { pageSetupData } = useConfigurations();
  const history = useHistory();
  const ref = useRef();
  const pageSetupRow = pageSetupData.find(
    element => element.type === 'prescription'
  );
  const paddingTop =
    pageSetupRow?.top * CENTERMETER_TO_PIXEL > 0
      ? pageSetupRow?.top * CENTERMETER_TO_PIXEL
      : 10;
  const paddingRight =
    pageSetupRow?.right * CENTERMETER_TO_PIXEL > 0
      ? pageSetupRow?.right * CENTERMETER_TO_PIXEL
      : 10;
  const paddingBottom =
    pageSetupRow?.bottom * CENTERMETER_TO_PIXEL > 0
      ? pageSetupRow?.bottom * CENTERMETER_TO_PIXEL
      : 10;
  const paddingLeft =
    pageSetupRow?.left * CENTERMETER_TO_PIXEL > 0
      ? pageSetupRow?.left * CENTERMETER_TO_PIXEL
      : 10;
  const PrescriptionPage = ({ prescription, appId, index, date }) => {
    const printRef = useRef();

    return (
      <Div key={index} height={HEIGHT} p={4}>
        <Div>
          <Div>
            <ReactToPrint
              trigger={() => (
                <PrinterOutlined style={{ marginRight: '10px' }} />
              )}
              content={() => printRef.current}
            />
            <EditOutlined
              onClick={() => history.push(`/appointments/${appId}`)}
            />
            <CRDivider />
          </Div>
          <Div
            ref={printRef}
            pt={paddingTop}
            pr={paddingRight}
            pb={paddingBottom}
            pl={paddingLeft}
          >
            <Div display="flex">
              <Div fontWeight="700" width="50px">
                Name:
              </Div>
              <Div ml="10px">{patient?.name}</Div>
            </Div>

            <Div display="flex">
              <Div fontWeight="700" width="50px">
                Date:
              </Div>
              <Div ml="10px">{date}</Div>
            </Div>

            <CRDivider />
            {prescription.map(({ medicine, dose }, idx) => (
              <Div key={idx}>
                <H5 mb={0}>{createDescription(medicine)}</H5>
                <Div>{dose}</Div>
              </Div>
            ))}
          </Div>
        </Div>
      </Div>
    );
  };
  return (
    <ContainerStyled>
      <WrapperStyled ref={ref}>
        <Slider dots={null}>
          {prescriptions.map(({ appId, prescription, date }, index) => (
            <PrescriptionPage
              prescription={prescription}
              appId={appId}
              index={index}
              date={formatDate(date, FULL_DAY_FORMAT)}
              patientName={patient?.name}
            />
          ))}
        </Slider>
      </WrapperStyled>
    </ContainerStyled>
  );
};

Prescriptions.defaultProps = {
  prescriptions: [],
};

export default Prescriptions;
