import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { Alert } from 'rsuite';
import {
  ADD_SESSION_DEFINITION,
  EDIT_SESSION_DEFINITION,
  LIST_SESSIONS_DEFINITION,
  LIST_SESSION_STATISTICS,
} from 'apollo-client/queries';
import client from 'apollo-client/client';

const updateCache = mySessionsDefinition => {
  client.writeQuery({
    query: LIST_SESSIONS_DEFINITION,
    data: {
      mySessionsDefinition,
    },
  });
};

function useSessionDefinition({
  onCreate,
  onEdit,
  sessionsIds,
  dateFrom,
  dateTo,
  page = 1,
  name,
  hasPagination = false,
} = {}) {
  const { data } = useQuery(LIST_SESSIONS_DEFINITION, {
    variables: Object.assign(
      {
        offset: (page - 1) * 20 || 0,
        limit: 20,
        hasPagination,
      },
      name && { name: name }
    ),
    fetchPolicy: 'cache-first',
  });
  const sessionsData = data?.mySessionsDefinition;

  const sessionsDefinition = useMemo(() => {
    return R.propOr([], 'sessionsDefinations')(sessionsData);
  }, [sessionsData]);

  const sessionsCount = useMemo(
    () => R.propOr(0, 'sessionsDefinationsCount')(sessionsData),
    [sessionsData]
  );
  const pages = Math.ceil(sessionsCount / 20);

  const { data: sessionData } = useQuery(LIST_SESSION_STATISTICS, {
    variables: Object.assign(
      { sessionsIds: sessionsIds },
      dateFrom && { dateFrom },
      dateTo && { dateTo }
    ),
  });
  const sessionStatistics = useMemo(
    () => R.propOr([], 'mySessionStatistic')(sessionData),
    [sessionData]
  );

  const [addSessionDefinition, { loading }] = useMutation(
    ADD_SESSION_DEFINITION,
    {
      onCompleted() {
        Alert.success('the Session has been Added Successfully');
        onCreate && onCreate();
      },
      refetchQueries: [
        {
          query: LIST_SESSIONS_DEFINITION,
          variables: Object.assign(
            {
              offset: (page - 1) * 20 || 0,
              limit: 20,
              hasPagination: false,
            },
            name && { name: name }
          ),
        },
        {
          query: LIST_SESSIONS_DEFINITION,
          variables: Object.assign(
            {
              offset: (page - 1) * 20 || 0,
              limit: 20,
              hasPagination: true,
            },
            name && { name: name }
          ),
        },
      ],
      onError() {
        Alert.error('Failed to add new Session');
      },
    }
  );
  const [editSessionDefinition] = useMutation(EDIT_SESSION_DEFINITION, {
    onCompleted() {
      Alert.success('the Session has been Edited Successfully');
      onEdit && onEdit();
    },
    refetchQueries: [
      {
        query: LIST_SESSIONS_DEFINITION,
        variables: Object.assign(
          {
            offset: (page - 1) * 20 || 0,
            limit: 20,
            hasPagination: false,
          },
          name && { name: name }
        ),
      },
      {
        query: LIST_SESSIONS_DEFINITION,
        variables: Object.assign(
          {
            offset: (page - 1) * 20 || 0,
            limit: 20,
            hasPagination: true,
          },
          name && { name: name }
        ),
      },
    ],
    onError() {
      Alert.error('Failed to edit the Session');
    },
  });

  return useMemo(
    () => ({
      sessionsDefinition,
      addSessionDefinition,
      editSessionDefinition,
      sessionStatistics,
      updateCache,
      loading,
      sessionsCount,
      pages,
    }),
    [
      sessionsDefinition,
      addSessionDefinition,
      editSessionDefinition,
      sessionStatistics,
      loading,
      sessionsCount,
      pages,
    ]
  );
}

export default useSessionDefinition;
